<template>
  <div class="detail">
    <!-- 运单详情 -->
    <nav-bar :nav-info='navInfo'></nav-bar>
     <!-- 订单信息 -->
    <div class="normal_order_card" v-if="detailOrderNo.length!=0">
      <div class="normal_order_no">
        <div class="order_no_left">
          <img	:src="require('../../assets/img/icon/cg_order2x.png')"	/>
          订单编号：{{detailOrderNo.orderNo}}
        </div>
      </div>
      <!-- 收货信息 -->
      <div class="carrer_info">
        <div class="carrer_info_item">
          <div >
          <img	:src="require('../../assets/img/icon/order_user2x.png')"	/>
          收货人：{{detailOrderNo.receiverName}}
          </div>
          <div>
						<img	:src="require('../../assets/img/icon/order_tel.png')"	/>
            {{detailOrderNo.phone}}
          </div>
        </div>
        <div class="carrer_info_item">
          <div >
            <img	:src="require('../../assets/img/icon/order_adress2x.png')"	/>
            {{detailOrderNo.address}}
          </div>
        </div>
        <div class="params_info_item">
          <div >
            体积：{{detailOrderNo.cargoSize || 0}}m³
          </div>
          <div >
            重量：{{detailOrderNo.cargoWeight || 0}}t
          </div>
          <div >
            数量：{{detailOrderNo.cargoNum || 0}}
          </div>
        </div>
      </div>
    </div>
		<!-- tab切换 -->
		<div class="tabBar">
			<span :class="[currentTabIndex==0?'active':'']" @click="changeTab(0)">车辆信息</span>
			<span :class="[currentTabIndex==1?'active':'']" @click="changeTab(1)">运单信息</span>
		</div>
		<!-- 车辆信息 运单信息 -->
		 <!-- currentTabIndex==0 车辆-->
		<div class="driverDetails" v-if="currentTabIndex==0">
			<div v-if="noDriverInfo">
				<div class="driverDetails_item" >
					<div class="driver_cz">
						<div class="driver_edit" @click="editDriver(driverList)">修改</div>
						<div class="driver_del"  @click="delDriver(driverList)">删除</div>
					</div>
					<div class="orderNo">
						<div class="orderNo_no">{{driverList.driverName}}</div>
					</div>
					<p>
						<span>车牌号：{{driverList.carNo}}</span>
						<span>电话：{{driverList.phone}}</span>
					</p>
				</div>
			</div>
			<div v-else>
				<van-empty
					class="custom-image"
					image="https://img01.yzcdn.cn/vant/empty-image-default.png"
					description="没有车辆信息"
				/>
			</div>
		</div>
		<div class="driverDetails" v-else>
			<!-- 运单信息 -->
			<div class="order_detail">
				<div class="order_detail_item">
					<h4>管理订单</h4>
					<div>
						<span><i>订单类型：</i>{{detailOrderNo.type==1?'常规类型':'计划类型'}}</span>
						<span v-if="detailOrderNo.status==22"><i>订单状态：</i>待出库</span>
						<span v-if="detailOrderNo.status==23"><i>订单状态：</i>在途</span>
						<span v-if="detailOrderNo.status==24"><i>订单状态：</i>完成</span>
					</div>
					<div>
						<span><i>用车企业：</i>{{detailOrderNo.erpName}}</span>
					</div>
					<div>
						<span><i>到仓时间：</i> {{detailOrderNo.useTime|format('-')}} </span>
					</div>
				</div>
					<div class="order_detail_item">
					<h4>基本信息</h4>
					<div>
						<span><i>预计完成时间：</i>{{detailOrderNo.expectFinishTime |format('-')}}</span>
						<span><i>运单费用：</i>{{detailOrderNo.cost}}元</span>
					</div>
					<div>
						<span><i>是否自由配送：</i>{{detailOrderNo.dispatchType=1?'否':'是'}}</span>
					</div>
				</div>
					<div class="order_detail_item">
					<h4>发货信息</h4>
					<div>
						<span><i>发货方：</i>{{detailOrderNo.shipperName}}</span>
					</div>
					<div>
						<span><i>联系方式：</i>{{detailOrderNo.shipperPhone}}</span>
					</div>
					<div>
						<span><i>始发地：</i>{{detailOrderNo.shipperAddress}}</span>
					</div>
				</div>
					<div class="order_detail_item">
					<h4>货物信息</h4>
					<div>
						<span><i>货物类型：</i>{{detailOrderNo.cargoModel}}</span>
						<span><i>货物名称：</i>{{detailOrderNo.cargoType}}</span>
					</div>
					<div>
						<span><i>声明价值：</i>{{detailOrderNo.cargoWorth || 0}}元</span>
					</div>
					<!-- <div>
						<span><i>到仓时间：</i>{{detailOrderNo.useTime}}</span>
					</div> -->
				</div>
					<div class="order_detail_item">
					<h4>收货信息</h4>
					<div>
						<span><i>子订单号：</i>{{detailOrderNo.autocOrderNo}}</span>
					</div>
					<div>
						<span><i>备注：</i>{{detailOrderNo.note}}</span>
					</div>
				</div>
			</div>
		</div>
		 <!-- 新增司机 -->
    <div class="goPageBtn">
      <div class="add_driver" @click="addDriver">添加司机</div>
    </div>
  </div>
</template>
<script>
import { Toast } from 'vant';
import { Dialog } from 'vant';
import NavBar from '../../components/NavBar/NavBar.vue'
export default {
  components: {
    NavBar
  },
  data() {
    return {
      navInfo: {
        title:'运单详情',
        left:true,
        path:'/planWaybill'
      },
     driverList:[],//司机列表
     detailOrderNo:[],//订单详情
		 currentTabIndex:0,
		 noDriverInfo:true,
    }
  },
  created() {
    this.getData()
		console.log(window.sessionStorage.getItem('orderNo'));
  },
  methods: {
    async	getData() {//获取数据
			let params = {
				orderNo:window.sessionStorage.getItem('orderNo')
			}
			let res = await this.$Api.getWaybillDetail(params)
      console.log(res);
			if (res.data[0].driverInfo==null) {
				this.noDriverInfo = false
			} 
			this.driverList = res.data[0].driverInfo
			this.detailOrderNo = res.data[0].detail
			window.sessionStorage.setItem('waybillNo',this.driverList.waybillNo)
		},
		changeTab(value) {//切换tabs
     this.currentTabIndex = value
    },
		addDriver() {//添加司机
      console.log('新增司机');
      this.$router.push({
        path:'/register',
        query: {
          waybillNo:window.sessionStorage.getItem('waybillNo'),
          type:4,//运单新增
        }
      })
    },
		editDriver(item) {
			 this.$router.push({
        path:'/register',
        query: {
          waybillNo:window.sessionStorage.getItem('waybillNo'),
          driverInfo:item,
          type:4
        }
      })
		},
		 delDriver(item) {
			console.log(item);
			let params = {
				orderId:this.detailOrderNo.orderId,//订单ID
				waybillId:item.waybillId,//运单ID
			}
			Dialog.confirm({
				title: '删除',
				message: '是否确认删除运单下的该司机',
			}).then( async() => {
					let res = await this.$Api.delOrderBindingWaybill(params)
					if (res.data=='删除成功') {
						Toast("删除成功")
						setTimeout(() => {
              this.getData()
            }, 1000);
					} else {
						Toast("删除失败")
					}
				})
				.catch(() => {
					Toast("已取消")
				});
		
			
		},
  }

}
</script>
<style lang="scss"  scoped>
.detail {
  background-color: #f5f5f5;
  width: 100vw;
  height: 100vh;
}
.normal_order_card {
	background: #ffffff;
	border-radius: 0.133rem;
	margin: 0.16rem 0.426rem 0.133rem;
	.normal_order_no {
		padding: 0.21rem 0.267rem 0.133rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
		.order_no_left {
			flex: 1;
			font-size: 0.37rem;
			color: #999999;
			line-height:1.6;
			img {
				width: 0.64rem;
				height: 0.64rem;
				vertical-align: bottom;
			}
		}
	}
	.order_no_time {
		display: flex;
		padding: 0.213rem 0.267rem 0.133rem;
		height: 0.8rem;
		line-height: 0.8rem;
		justify-content: space-between;
		align-items: center;
		font-size: 0.32rem;
		color: #999999;
		div:nth-child(1) {
			flex: 1;
		}
		div:nth-child(2) {
			span {
				text-align: right;
				margin-right: 0.133rem;
			}
		}
	}
	.carrer_info {
		padding: 0 0.267rem;
		.carrer_info_item {
			display: flex;
			justify-content: space-between;
			align-items: center;
			line-height: 0.64rem;
			font-size: 0.37rem;
			div {
				line-height: 0.64rem;
				img {
					width: 0.64rem;
					height: 0.64rem;
					vertical-align: bottom;
				}
			}
			div:nth-child(1) {
				flex: 1;
			}
			div:nth-child(2) {
				color: #ff6600;
			}
			.carrer_info_item_color {
				flex: 1;
				text-align: left;
				color: #303133!important;
			}
		}
		.params_info_item {
			font-size: 0.32rem;
			color: #999999;
			display: flex;
			justify-content: space-between;
			align-items: center;
			height: 0.64rem;
			line-height: 0.64rem;
			margin-bottom: 0.133rem;
			div:nth-child(1) {
				margin-left: 0.2rem;
			}
			div:nth-child(3) {
				margin-right: 0.533rem;
			}
		}
	}
}
.tabBar {
  span{
    display: inline-block;
    margin:0 0.43rem;
    font-size: 0.42rem;
    color: #999999;
  }
  .active {
    color: #000000;
    font-size: 0.47rem;
    border-bottom: 0.1rem solid #FB6600;
  }
}
.driverDetails {
  margin: 0.27rem 0;
  // height: 11.5rem;
  height: 60vh;
  overflow-y: scroll;
  .driverDetails_item {
    position: relative;
    margin: 0.27rem 0.43rem;
		padding: .133333rem 0;
    background: #ffffff;
    border-radius: 0.17rem;
    .driver_cz {
      font-size: 0.32rem;
      position: absolute;
      top: 0;
      right:0;
      display: flex;
      flex-direction: column;
      div {
        margin-top: 0.3rem;
        flex: 1;
        padding: 0.05rem 0.2rem;
        border-radius: 0.3rem;
        color: #fff;
        &:nth-child(1) {
          background-color: #0094ff;
        }
        &:nth-child(2) {
					background-color: #ff6600;
        }
      }
    }
    .orderNo {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .orderNo_no {
        margin-left: 0.27rem;
        font-size: 0.37rem;
        flex: 1;
        height: 0.64rem;
        line-height: 0.64rem;
        padding-left: 0.64rem;
        background: url("../../assets/img/icon/waybill_user2x.png");
        background-size: 0.64rem;
        background-repeat: no-repeat;
      }
      .orderNo_img {
        margin-left: 0.43rem;
        width: 0.64rem;
        // height: 0.64rem;
        margin-top: -0.64rem;
        img {
          height: 100%;
        }
      }
    }
    p {
      padding: 0.27rem 0.43rem;
      font-size: 0.37rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        flex: 1;
        text-align: left;
      }
    }
  }
	// 订单详情
.order_detail {
	margin: 0.27rem 0.43rem;
	background: #ffffff;
	border-radius: 0.17rem;
	.order_detail_item {
		padding: .133333rem .426667rem;
		h4 {
			font-size: 0.37rem;
			color: #ff6600;
		}
		div {
			padding: .106667rem 0;
			font-size: 0.32rem;
			display: flex;
			justify-content: space-between;
			align-items: center;
			span {
				color: #999999;
				i {
					color: #000000;
					font-style: normal;
				}
			}
			span:nth-child(1) {
				flex: 1;
			}
			span:nth-child(2) {
				width: 272rpx;
			}
		}
	}
}
}
.add_driver {
  position: fixed;
  bottom: 20px;
  left: 0;
  margin-left: 10vw;
  text-align: center;
  line-height: 38px;
  border-radius: 19px;
  font-size: 0.42rem;
  color: #fff;
  background-color: #ff6600;
  height: 38px;
  width: 80vw;
}
</style>